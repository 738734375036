/**
 */
function initIsRequiredValidator(elements,eventTypes) {
    var selectEventType = false;
    if(typeof eventTypes === 'undefined'){
        selectEventType = true;
    }else if(typeof eventTypes === 'string'){
        eventTypes = [eventTypes];
    }
    $(elements).each(function(i,element){
        if(selectEventType){
            eventTypes = getEventTypeByElement(element);
        }
        $.each(eventTypes,function(i,eventType){
            if(typeof eventType === 'undefined'){
                return;
            }
            $(element).unbind(eventType+'.'+ Validator.NsIsRequired)
                      .on(eventType+'.'+ Validator.NsIsRequired ,function(event){
                if(typeof event.isValid === 'undefined'){
                    event.isValid = true;
                }
                if(!event.isValid){
                    return;
                }
                event.isValid = Validator.validateIsRequired(element);
            });
        });
    });
}

function initStringLengthValidator(elements,min,max) {
    $(elements).each(function(i,element){
        $(element).unbind('keyup.'+ Validator.NsStringLength)
                  .on('keyup.'+ Validator.NsStringLength,function(event){
            if(typeof event.isValid === 'undefined'){
                event.isValid = true;
            }
            if(!event.isValid){
                return;
            }
            event.isValid = Validator.validateStringLength(element,min,max);
        });
    });
}

function initDataTypeValidator(elements,dataType) {
    $(elements).each(function(i,element){
        $(element).unbind('keyup.'+Validator.NsDataType)
                  .on('keyup.'+Validator.NsDataType,function(event){
            if(typeof event.isValid === 'undefined'){
                event.isValid = true;
            }
            if(!event.isValid){
                return;
            }
            event.isValid = Validator.validateDataType(element,dataType);
        });
    });
}

function initGreaterThanValidator(elements,min,inclusive,property) {
    $(elements).each(function(i,element){
        var compareElement = false;
        if(property){
            compareElement = $(element).parents('form').find('*[name='+property+']');
        }
        $(element)
                .unbind('keyup.'+Validator.NsNotGreaterThan)
                .on('keyup.'+Validator.NsNotGreaterThan,function(event){
            if(typeof event.isValid === 'undefined'){
                event.isValid = true;
            }
            if(!event.isValid){
                return;
            }
            if(compareElement){
                min = parseFloat(
                    $(compareElement).val());
            }
            event.isValid = Validator.validateGreaterThan(element,min,inclusive,property);
        });
        if(compareElement){
            $($(compareElement).parents('form')).find('*[name='+property+']')
                .on('keyup.compare'+Validator.NsNotGreaterThan,function(compareEvent){
                if(compareEvent);
                var min = parseFloat($(compareEvent.currentTarget).val());
                var isValid = Validator.validateGreaterThan(element,min,inclusive,property);
            });
        }
    });
}

function initBetweenValidator(elements,min,max,inclusive) {
    $(elements).each(function(i,element){
        $(element).unbind('keyup.'+Validator.NsValueNotBetween)
                  .on('keyup.'+Validator.NsValueNotBetween,function(event){
            if(typeof event.isValid === 'undefined'){
                event.isValid = true;
            }
            if(!event.isValid){
                return;
            }
            event.isValid = Validator.validateBetween(element,min,max,inclusive);
        });
    });
}


function getEventTypeByElement(element) {
    var definitions = {
        'input': ['keyup','click'],
        'select': ['change'],
        'textarea': ['keyup']
    };
    if($(element).length !== 1 ||
        typeof definitions[$(element).get(0).tagName.toLowerCase()] === 'undefined'){
        return false;
    }
    var tagName = $(element).get(0).tagName.toLowerCase();
    if(tagName === 'input' && $(element).attr('type') === 'text'){
        delete definitions[tagName][1];
    }
    return definitions[tagName];
}
